:host([variant="highlight"]) {
  --accent-color: var(--color-highlight);
  --accent-hover-color: var(--color-darker-highlight);
  --text-color: #000000b3;
}

:host([outlined]) button {
  background-color: transparent;
  color: var(--accent-color);
  border-color: var(--accent-color);
}

:host([outlined]) button:hover {
  color: var(--text-color);
  background-color: var(--accent-hover-color);
}

:host([unstyled]) {
  color: currentColor;
}

:host([unstyled]) button {
  background-color: transparent;
  border-color: transparent;
}
